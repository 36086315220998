import { createActionAsync } from 'redux-act-async';
import $api from 'src/utils/api-interface';

export const getSlackUsers = createActionAsync('GET_SLACK_USERS', (ids: string[]) =>
    $api
        .get({
            url: '/slack-users',
            params:
                ids?.length > 0
                    ? {
                          ['_ids']: ids.join(',')
                      }
                    : undefined
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getSlackUsersPhone = createActionAsync('GET_SLACK_USERS_PHONE', (query) => {
    const params = (() => (query ? { search: query } : {}))();
    return $api
        .get({
            url: '/slack-users-phone',
            params
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
});

export const getMsTeamsUsersPhones = createActionAsync('GET_MS_TEAMS_USERS_PHONE', (query: string) => {
    return $api
        .get({
            url: '/ms-teams-users',
            params: { query }
        })
        .then((res) =>
            res.data.users.map(({ _id: id, profile: { display_name: name, mobile_phone: phone } }) => ({
                id,
                name,
                phone
            }))
        )
        .catch((error) => {
            throw error;
        });
});

export const getSlackChannels = createActionAsync('GET_SLACK_CHANNELS', () =>
    $api
        .get({ url: '/slack-channels' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);
