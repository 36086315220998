import {
    createWorkflow,
    getWorkflows,
    clearWorkflows,
    createWorkflowTrigger,
    updateWorkflow,
    updateWorkflowSteps
} from 'src/actions/workflows/workflows-actions';
import { LoadStatus } from 'src/constants/load-status';
import produce from 'immer';

interface IDefaultState {
    workflows: IWorkflows.IWorkflow[];
    hasNextPage: boolean;
    getWorkflowsLoadStatus: string;
    createWorkflowLoadStatus: string;
    updateWorkflowLoadStatus: string;
    createWorkflowTriggerLoadStatus: string;
    updateWorkflowStepsLoadStatus: string;
}

export const defaultState: IDefaultState = {
    workflows: [],
    hasNextPage: false,
    getWorkflowsLoadStatus: LoadStatus.EMPTY,
    createWorkflowLoadStatus: LoadStatus.EMPTY,
    updateWorkflowLoadStatus: LoadStatus.EMPTY,
    createWorkflowTriggerLoadStatus: LoadStatus.EMPTY,
    updateWorkflowStepsLoadStatus: LoadStatus.EMPTY
};

export default function (state = defaultState, action: Common.IReducerAction) {
    return produce(state, (draft) => {
        switch (action.type) {
            // Create workflow
            case createWorkflow.request.getType():
                draft.createWorkflowLoadStatus = LoadStatus.REQUEST;
                return;
            case createWorkflow.ok.getType():
                draft.createWorkflowLoadStatus = LoadStatus.OK;
                return;
            case createWorkflow.error.getType():
                draft.createWorkflowLoadStatus = LoadStatus.ERROR;
                return;

            // Get workflows
            case getWorkflows.request.getType():
                draft.getWorkflowsLoadStatus = LoadStatus.REQUEST;
                return;
            case getWorkflows.ok.getType():
                const newWorkflows = [...state.workflows, ...action.payload.response.workflows];
                draft.workflows = newWorkflows;
                const { limit, offset, total } = action.payload.response.pagination;
                const hasNextPage = offset + limit < total;
                draft.hasNextPage = hasNextPage;
                draft.getWorkflowsLoadStatus = LoadStatus.OK;
                return;
            case getWorkflows.error.getType():
                draft.getWorkflowsLoadStatus = LoadStatus.ERROR;
                return;

            // Clear workflows
            case clearWorkflows.getType():
                return defaultState;

            // Create workflow trigger
            case createWorkflowTrigger.request.getType():
                draft.createWorkflowTriggerLoadStatus = LoadStatus.REQUEST;
                return;
            case createWorkflowTrigger.ok.getType():
                draft.createWorkflowTriggerLoadStatus = LoadStatus.OK;
                return;
            case createWorkflowTrigger.error.getType():
                draft.createWorkflowTriggerLoadStatus = LoadStatus.ERROR;
                return;
            case updateWorkflow.request.getType():
                draft.updateWorkflowLoadStatus = LoadStatus.REQUEST;
                return;
            case updateWorkflow.ok.getType():
                draft.updateWorkflowLoadStatus = LoadStatus.OK;
                const updatedWorkflow = action.payload.response;
                draft.workflows = state.workflows.map((workflow) => {
                    if (workflow.id === updatedWorkflow.id) {
                        return {
                            ...workflow,
                            active: updatedWorkflow.active,
                            name: updatedWorkflow.name
                        };
                    }
                    return workflow;
                });
                return;
            case updateWorkflow.error.getType():
                draft.updateWorkflowLoadStatus = LoadStatus.ERROR;
                return;
            case updateWorkflowSteps.request.getType():
                draft.updateWorkflowStepsLoadStatus = LoadStatus.REQUEST;
                return;
            case updateWorkflowSteps.ok.getType():
                draft.updateWorkflowStepsLoadStatus = LoadStatus.OK;
                return;
            case updateWorkflowSteps.error.getType():
                draft.updateWorkflowStepsLoadStatus = LoadStatus.ERROR;
                return;
        }
    });
}
