import $api from 'src/utils/api-interface';
import { createActionAsync } from 'redux-act-async';

export const getMsTeamsTeams = createActionAsync('GET_MS_TEAMS_TEAMS', () =>
    $api
        .get({ url: '/ms-teams-groups?withAppInstalled=true' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getMsTeamsUsers = createActionAsync('GET_MS_TEAMS_USERS', (name: string) =>
    $api
        .get({ url: `/ms-teams-users?query=${name}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);
